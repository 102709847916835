import { environment } from '../../../environments/environment';
import { I18nString } from '../class/i18nstring';
import { Deletable } from '../interface/deletable';
import { Factory } from '../interface/factory';
import { Identifiable } from '../interface/identifiable';
import { Asset } from './asset';
import { Duration } from './duration';
import { Schedule } from './schedule';

export enum BookableType {
  CargoBike = 'CargoBike',
  Generic = 'Generic',
  MeetingRoom = 'MeetingRoom',
}

export class Bookable implements Deletable, Identifiable {
  id: string;
  deleted: boolean;

  hourlyPrice: number;
  hourlyPriceVat: number;

  type: BookableType;

  tenantUnitId: string;
  srenityRoomId?: string;
  names: I18nString[];
  abouts: I18nString[];

  maxSimultaneousBookings: number;
  slotLength: Duration;
  maxLength: Duration;
  minCancelationLength: Duration;
  availability: Schedule<boolean>;

  created: Date;
  modified: Date;

  sharedWith: string[];

  constructor(json: any, type: BookableType) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;

    this.hourlyPrice = json.hourlyPrice ?? 0;
    this.hourlyPriceVat = json.hourlyPriceVat ?? 0;

    this.type = type;

    this.tenantUnitId = json.tenantUnitId;
    this.srenityRoomId = json.srenityRoomId;
    this.names = json.names ? json.names.map((json: any) => new I18nString(json)) : [];
    this.abouts = json.abouts ? json.abouts.map((json: any) => new I18nString(json)) : [];

    this.maxSimultaneousBookings = Number(json.maxSimultaneousBookings);
    this.slotLength = new Duration(json.slotLength);
    this.maxLength = new Duration(json.maxLength);
    this.minCancelationLength = new Duration(json.minCancelationLength);
    this.availability = new Schedule<boolean>(json.availability);

    this.created = json.created;
    this.modified = json.modified;

    this.sharedWith = json.sharedWith ? json.sharedWith : [];
  }

  static getFactory(): Factory<Bookable> {
    return new (class implements Factory<Bookable> {
      make(json: any): Bookable {
        switch (json.type) {
          case BookableType.Generic:
            return new GenericBookable(json);
          case BookableType.CargoBike:
            return new CargoBike(json);
          case BookableType.MeetingRoom:
            return new MeetingRoom(json);
          default:
            throw new Error('Unrecognized Bookable type (' + json.type + ').');
        }
      }

      getTableName(): string {
        return 'bookables';
      }
    })();
  }

  static getUrl(tenantUnitId: string, bookableId?: string): string;
  static getUrl(tenantUnitId: string, bookableId: string): string {
    return '/tenant_units/' + tenantUnitId + '/bookables' + (bookableId ? '/' + bookableId : '');
  }
}

export class GenericBookable extends Bookable {
  categoryId?: string;
  images: string[];

  constructor(json: any) {
    super(json, BookableType.Generic);
    this.categoryId = json.categoryId ? json.categoryId : undefined;
    this.images = json.images ? json.images : [];
  }

  getImageUrl(index: number = 0): string | undefined {
    return this.images[index] ? environment.blobUrl + '/images/' + this.images[index] : undefined;
  }
}

export class CargoBike extends Bookable {
  code: string;
  images: string[];

  constructor(json: any) {
    super(json, BookableType.CargoBike);
    this.code = json.code;
    this.images = json.images ? json.images : [];
  }

  getImageUrl(index: number = 0): string | undefined {
    return this.images[index] ? environment.blobUrl + '/images/' + this.images[index] : undefined;
  }
}

export class MeetingRoom extends Bookable {
  capacity: number;
  size: number;
  images: string[];
  assets: Asset[];

  constructor(json: any) {
    super(json, BookableType.MeetingRoom);
    this.capacity = Number(json.capacity);

    this.size = json?.size !== undefined || json?.size !== null ? Number(json.size) : 10;

    this.images = json.images ? json.images : [];
    const assetFactory = Asset.getFactory();
    this.assets = json.assets ? json.assets.map((aj: any) => assetFactory.make(aj)) : [];
  }

  getImageUrl(index: number = 0): string | undefined {
    return this.images[index] ? environment.blobUrl + '/images/' + this.images[index] : undefined;
  }
}
