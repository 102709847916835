import { environment } from '../../../environments/environment';
import { I18nString } from '../class/i18nstring';
import { PublishStatus } from '../enum/publish-status';
import { Deletable } from '../interface/deletable';
import { Factory } from '../interface/factory';
import { Identifiable } from '../interface/identifiable';
import { Duration } from './duration';
import { Schedule } from './schedule';

export class GenericBookableCategory implements Deletable, Identifiable {
  id: string;
  deleted: boolean;
  deletedDbFlag: 0 | 1; // IndexDB can't index by boolean so we use a number to enable indexing by deleted.

  tenantUnitId: string;

  status: PublishStatus;

  names: I18nString[];
  longDescriptions: I18nString[];
  shortDescriptions: I18nString[];
  images: string[];

  // category default settings
  maxSimultaneousBookings: number;
  slotLength: Duration;
  maxLength: Duration;
  minCancelationLength: Duration;
  availability: Schedule<boolean>;

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = json.deleted ? 1 : 0;

    this.tenantUnitId = json.tenantUnitId;

    this.status = json.status as PublishStatus;

    this.names = json.names ? json.names.map((json: any) => new I18nString(json)) : [];
    this.longDescriptions = json.longDescriptions ? json.longDescriptions.map((json: any) => new I18nString(json)) : [];
    this.shortDescriptions = json.shortDescriptions
      ? json.shortDescriptions.map((json: any) => new I18nString(json))
      : [];
    this.images = json.images ? json.images : [];

    this.maxSimultaneousBookings = Number(json.maxSimultaneousBookings);
    this.slotLength = new Duration(json.slotLength);
    this.maxLength = new Duration(json.maxLength);
    this.minCancelationLength = new Duration(json.minCancelationLength);

    this.availability = new Schedule<boolean>(json.availability);

    this.created = json.created;
    this.modified = json.modified;
  }

  public static getFactory(): Factory<GenericBookableCategory> {
    return new (class implements Factory<GenericBookableCategory> {
      make(json: any): GenericBookableCategory {
        return new GenericBookableCategory(json);
      }
      getTableName(): string {
        return 'generic_bookable_categories';
      }
    })();
  }

  static getUrl(tenantUnitId: string, categoryId?: string): string;
  static getUrl(tenantUnitId: string, categoryId: string): string {
    return '/tenant_units/' + tenantUnitId + '/generic_bookable_categories' + (categoryId ? '/' + categoryId : '');
  }

  getImageUrl(index: number = 0): string | undefined {
    return this.images[index] ? environment.blobUrl + '/images/' + this.images[index] : undefined;
  }
}
