import { Deletable } from '../interface/deletable';
import { Factory } from '../interface/factory';
import { Identifiable } from '../interface/identifiable';
import { Features } from './tenant-unit';

class Location {
  lat: number;
  lng: number;

  constructor(json: any) {
    this.lat = json.lat;
    this.lng = json.lng;
  }
}

export class Organization implements Deletable, Identifiable {
  id: string;
  deleted?: boolean;

  name: string;
  address: string;
  postal: string;
  city: string;
  country?: string;
  location?: Location;
  phone: string;
  email: string;

  created: Date;
  modified: Date;

  standardFeatureFlags: Features;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;

    this.name = json.name;
    this.address = json.address;
    this.postal = json.postal;
    this.city = json.city;
    this.country = json.country ? json.country : 'Sweden';
    this.location = json.location ? new Location(json.location) : new Location({ lat: 57, lng: 12 });
    this.phone = json.phone;
    this.email = json.email;

    this.created = json.created;
    this.modified = json.modified;

    this.standardFeatureFlags = json.standardFeatureFlags
      ? new Features(json.standardFeatureFlags)
      : new Features({
          bt_locks: false,
          wifi_locks: false,
          imd: false,
          groups: true,
          chats: true,
          events: true,
          bookings: true,
          offers: true,
          feed: true,
          external_reports: false,
          advanced_reports: false,
        });
  }

  public static getFactory(): Factory<Organization> {
    return new (class implements Factory<Organization> {
      make(json: any): Organization {
        return new Organization(json);
      }
      getTableName(): string {
        return 'organizations';
      }
    })();
  }

  static getUrl(organizationId?: string): string {
    return '/organizations/' + (organizationId ? organizationId : '');
  }
}
