export enum Scope {
  None = 0,

  ApartmentResident = 1,
  ApartmentContentAdmin = 2,
  ApartmentBillingAdmin = 4,
  ApartmentPersonnelAdmin = 8,

  TenantUnitContentAdmin = 16,
  TenantUnitBillingAdmin = 32,
  TenantUnitPersonnelAdmin = 64,

  GlobalContentAdmin = 128,
  GlobalBillingAdmin = 256,
  GlobalPersonnelAdmin = 512,

  TenantUnitBoardMember = 1024,

  ApartmentContractOwner = 2048,
  // Open slot.
  ApartmentShowOnDoor = 8192,
  ApartmentShowOnDoorPhone = 16384,

  OrganizationAdmin = 65536,
}
